<template>
  <div class="personalCenter">
    <el-radio-group v-model="tableType">
      <el-radio-button :label="0">基本信息</el-radio-button>
      <el-radio-button :label="1">修改密码</el-radio-button>
    </el-radio-group>
    <div class="form">
      <el-form :model="userInfoForm"
               :rules="userInfoRules"
               label-width="100px"
               ref="userInfoForm">
        <el-form-item label="我的头像：">
          <el-upload class="avatar-uploader"
                     action="/api/base/uploadByOss.do"
                     :data="{path:'personalCenter',name:''}"
                     :show-file-list="false"
                     :headers="{Authorization:token}"
                     :on-success="iconSuccess">
            <img v-if="userInfoForm.icon"
                 :src="userInfoForm.iconUrl"
                 class="avatar">
            <i v-else
               class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="名字：">
          {{userInfoForm.userName}}
          <!-- <el-input v-model="userInfoForm.userName"
                    class="input-box"></el-input> -->
        </el-form-item>
        <el-form-item label="所教学科：">
          {{userInfoForm.courseSubject}}
        </el-form-item>
        <el-form-item label="电话：">
          {{userInfoForm.telephone}}
          <!-- <el-input v-model="userInfoForm.telephone"
                    class="input-box"></el-input> -->
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="userInfoForm.email"
                    class="input-box"></el-input>
        </el-form-item>
        <!-- <el-form-item label="地址：">
          <el-input v-model="userInfoForm.address"
                    class="input-box"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button type="primary"
                   @click="modifyPersonal">修 改</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="passwordsVisible"
               width="600px"
               title="修改密码">
      <el-form :model="pwdForm"
               :rules="pwdFormRules"
               class="dialog-form"
               ref="pwdForm"
               label-width="80px">
        <el-form-item label="密码"
                      prop="pwd">
          <el-input v-model="pwdForm.pwd"
                    type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"
                      prop="repeatPwd">
          <el-input v-model="pwdForm.repeatPwd"
                    type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary"
                   @click="resetPwd">确 认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "personalCenter",
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      userInfoForm: {
        userName: "",
        icon: "",
        iconUrl: "",
        telephone: "",
        email: "",
        address: "",
        courseSubject: "",
      },
      userInfoRules: {},
      tableType: 0,
      passwordsVisible: false,
      pwdForm: {
        pwd: "",
        repeatPwd: "",
      },
      pwdFormRules: {
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        repeatPwd: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
      token: sessionStorage.getItem("token"),
    };
  },
  watch: {
    tableType: function (newTableType) {
      if (newTableType === 1) {
        this.passwordsVisible = true;
        this.$nextTick(() => {
          this.$refs["pwdForm"].resetFields();
        });
      }
    },
  },
  created() {
    this.userInfoForm.iconUrl = this.$fileUploadDomain + this.userInfo.icon;
    this.userInfoForm.icon = this.userInfo.icon;
    this.userInfoForm.email = this.userInfo.email;
    this.userInfoForm.userName = this.userInfo.username;
    this.userInfo.gradeList.forEach((garde) => {
      this.userInfoForm.garde = garde.gradename;
      garde.list.forEach((cls) => {
        this.userInfoForm.cls = cls.classname;
      });
    });
    this.userInfoForm.telephone = this.userInfo.usermobile;
    this.userInfoForm.courseSubject = this.userInfo.courseSubject;
  },
  methods: {
    modifyPersonal() {
      this.$store.commit("modifyUserName", this.userInfoForm.userName);
      this.$confirm("是否确认修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateManager.do", {
            id: this.userInfo.userid,
            icon: this.userInfoForm.icon,
            email: this.userInfoForm.email,
          })
            .then((res) => {
              this.$store.commit("modifyUserIcon", this.userInfoForm.icon);
              this.$message({
                type: "success",
                message: "修改成功!",
              });
            })
            .catch((error) => {
              this.$message.error(error.message);
            });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    resetPwd() {
      if (this.pwdForm.pwd === this.pwdForm.repeatPwd) {
        this.$confirm("是否重置密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$post("/media/updatePassword.do", {
              id: this.pwdForm.id,
              password: this.pwdForm.pwd,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "重置成功!",
                });
                this.tableType = 0;
                this.passwordsVisible = false;
              })
              .catch((error) => {
                this.$message.error(error.message);
              });
          })
          .catch((err) => {
            this.$message({
              type: "info",
              message: "已取消重置",
            });
          });
      } else {
        this.$message({
          type: "warning",
          message: "密码与原密码一致",
        });
      }
    },
    cancel() {
      this.tableType = 0;
      this.passwordsVisible = false;
    },
    iconSuccess(res, file) {
      this.userInfoForm.iconUrl =
        this.$fileUploadDomain + file.response.message;
      this.userInfoForm.icon = file.response.message;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.input-box {
  width: 900px;
}
.form {
  margin-top: 20px;
}
.dialog-form {
  margin-left: 100px;
}
</style>
